import React from 'react';

const ServisInfoCom = (props) => {
  return (
    <section>
      <h3>{props.title}</h3>
      <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
    </section>
  );
};

export default ServisInfoCom;
