import React, { useEffect, useRef, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import logo from '../images/logo.svg';
import hamburger from '../images/hamburger.svg';
import leftArrow from '../images/arrow-left.svg';
import informacije from '../images/informacije.svg';
import layoutStyle from '../styles/Layout.module.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { appRoutes, detectLayoutColor } from '../helpers/utils';
import { Carousel } from 'react-responsive-carousel';
import zlLive from '../images/zlatibor-live.svg';

import closeIcon from '../images/close_menu.svg';
import useClickOutside from '../hooks/useClickOutside';
import { useDispatch, useSelector } from 'react-redux';
import { homeCategoryActions } from '../actions/categoryActions';
import { logout, secondCategoryGetByIdActions } from '../actions/cmsActions';
import { Button } from 'react-bootstrap';
import { secondCategoryActions } from '../actions/secondCategoryActions';

const Layout = () => {
  const dispatch = useDispatch();

  const sideMenuRef = useRef();

  const bodyHeight = window.innerHeight;

  const [openMenu, setOpenMenu] = useState(false);

  const location = useLocation();

  const data = location.state?.data;

  if (document.getElementById('mainSelect')) {
    document.getElementById('mainSelect').style.height = 'auto';
  }

  setTimeout(() => {
    if (document.getElementById('mainSelect')) {
      document.getElementById('mainSelect').style.minHeight =
        bodyHeight - document.getElementById('topContent').offsetHeight + 'px';
    }
  }, 1000);

  const detectedColor = detectLayoutColor(location.pathname);

  const openSideMenu = () => {
    setOpenMenu(true);
  };

  useClickOutside(sideMenuRef, () => {
    if (openMenu) {
      closeSideMenu();
    }
  });

  const closeSideMenu = () => {
    setOpenMenu(false);
  };

  const majorCategoryData = useSelector((state) => state.majorCategory);
  const { majorCategory } = majorCategoryData;

  useEffect(() => {
    dispatch(homeCategoryActions());
  }, [dispatch]);

  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;
  const admin = JSON.parse(localStorage.getItem('userInfo'));
  const history = useNavigate();
  const logoutHandler = () => {
    dispatch(logout());
    history('/cms-login');
  };

  const URL = location.pathname;

  const secondCategoryData = useSelector((state) => state.secondCategory);

  const allPostForParentData = useSelector((state) => state.allPostForParent);
  const { loading, error, allPostForParent } = allPostForParentData;
  const { secondCategory } = secondCategoryData;
  let idAll;
  if (URL.includes('de%C5%A1avanja')) {
    idAll = location.pathname.split('/')[2];
    // console.log('YES', idAll);
  }

  let id_by_one = location.pathname.split('/')[2];

  const secondCategoryGetByIdData = useSelector(
    (state) => state.secondCategoryGetById
  );
  const { secondCategoryGetById } = secondCategoryGetByIdData;

  // console.log('ID', secondCategoryGetById);

  useEffect(() => {
    dispatch(secondCategoryGetByIdActions(id_by_one));
  }, [dispatch, id_by_one]);

  // Vise

  // console.log('SECOND', secondCategory);s

  useEffect(() => {
    dispatch(secondCategoryActions(idAll));
  }, [dispatch, idAll]);

  const backHandler = () => {
    history(-1);
  };

  // console.log('PATH', location);

  return (
    <div id='topContent' className={detectedColor + ' pr'}>
      <header className='header-container'>
        <div className={layoutStyle.headerContainer + ' d-flex flex-row jcbtw'}>
          <div
            onClick={backHandler}
            style={{
              opacity:
                location.pathname !== '/' &&
                location.pathname !== '/cms' &&
                location.pathname !== '/cms-login'
                  ? 1
                  : 0,
            }}
            className={`${layoutStyle.backButton}`}
          >
            <img className='cp left-arrow-nav' src={leftArrow} alt='Back btn' />
          </div>
          <div className={layoutStyle.logoContainer}>
            <Link to='/'>
              <img src={logo} className='cp' alt='Logo' title='Logo' />
            </Link>
          </div>
        </div>

        <div
          onClick={openSideMenu}
          className={layoutStyle.menuContainer + ` ${detectedColor} pr`}
        >
          <div className={detectedColor + ' brd'}>
            <img
              style={{
                filter:
                  appRoutes.HOME == URL || URL.includes('servisne')
                    ? 'invert(100%)'
                    : '',
              }}
              src={hamburger}
              className='cp'
              alt='Menu'
              title='Menu'
            />
          </div>
        </div>
      </header>
      <section
        style={{
          textAlign: URL.includes(appRoutes.SERVICES_INFORMATION)
            ? 'center'
            : '',
          padding: URL.includes(appRoutes.SERVICES_INFORMATION)
            ? '15px 0px'
            : '',
        }}
        className={layoutStyle.sectionContainer}
      >
        {URL == appRoutes.HOME && (
          <div className={layoutStyle.layoutContent}>
            <img src={zlLive} alt='Zlatibor Live' className='homeImage' />
            {/* <h1 className="title">ZLATIBOR LIVE</h1> */}
            <h3 className='subtitle'>
              SVI DOGAĐAJI NA ZLATIBORU NA JEDNOM MESTU
            </h3>
          </div>
        )}

        {URL.includes(appRoutes.SERVICES_INFORMATION) && (
          <img
            alt='Servisne Informacije'
            title='Servisne Informacije'
            style={{
              width: '52px',
              height: '52px',
              display: 'flex',
              marginLeft: 40,
            }}
            src={informacije}
          />
        )}

        {URL.includes('sve-vesti') && (
          <Carousel
            showStatus={false}
            className='transform_bg_overlay'
            showThumbs={false}
          >
            {allPostForParent?.data?.data.map((element) => {
              return (
                <Link
                  to='/show-post'
                  style={{ textDecoration: 'none' }}
                  state={{ data: element }}
                >
                  <div className={detectedColor + ' h-100 image-radius'}>
                    <img
                      style={{ objectFit: 'cover', objectPosition: 'top' }}
                      src={`http://stage.zlatibor.live/api/image/${element.picture_name}`}
                    />
                    <p className='legend'>{element.title}</p>
                  </div>
                </Link>
              );
            })}
          </Carousel>
        )}

        {data && data !== null && data !== '' && (
          <Carousel
            className='transform_bg_overlay'
            autoPlay
            showStatus={false}
            showThumbs={false}
          >
            <div className='h-100 image-radius'>
              <img
                style={{ objectFit: 'cover', objectPosition: 'top' }}
                src={`http://stage.zlatibor.live/api/image/${data.picture_name}`}
              />
              <p className='legend'>{data.title}</p>
            </div>
          </Carousel>
        )}

        {/* {hasImage && !URL.includes(appRoutes.SERVICES_INFORMATION) && (
          <Carousel autoPlay showStatus={false} showThumbs={false}>
            {secondCategory?.data
              ? secondCategory?.data?.childs.map((child) => (
                  <div key={child.id}>
                    <img
                      className={layoutStyle.sectionImage}
                      alt={child.name}
                      title={child.name}
                      src={`/api/image/${child.image}`}
                    />
                    <p className="legend">{child.name}</p>
                  </div>
                ))
              : secondCategoryGetById?.data && (
                  <div>
                    <img
                      className={layoutStyle.sectionImage}
                      alt={secondCategoryGetById?.data?.name}
                      title={secondCategoryGetById?.data?.name}
                      src={`/api/image/${secondCategoryGetById?.data?.image}`}
                    />
                    <p className="legend">
                      {secondCategoryGetById?.data?.name}
                    </p>
                  </div>
                )}
          </Carousel>
        )} */}
      </section>

      {/* SIDE MENU */}
      {openMenu && (
        <div ref={sideMenuRef} className={layoutStyle.sideNavigationContainer}>
          <div className={layoutStyle.closeContainer} onClick={closeSideMenu}>
            <img src={closeIcon} className='cp' alt='Close' title='Close' />
          </div>
          <nav>
            <ul>
              {majorCategory?.data &&
                majorCategory.data.map((category) => (
                  <li
                    onClick={closeSideMenu}
                    key={category.id}
                    className={layoutStyle.navigationItem}
                  >
                    <Link
                      style={{ color: 'white', textDecoration: 'none' }}
                      to={
                        category.name === 'SERVISNE INFORMACIJE'
                          ? `/servisne-informacije/${category.id}`
                          : `/sve-vesti/${category.id}`
                      }
                    >
                      {category.name}
                    </Link>
                  </li>
                ))}
              {userInfo?.data ||
                (admin && (
                  <li>
                    <Button onClick={logoutHandler}>Odjavi se</Button>
                  </li>
                ))}

              {userInfo?.data ||
                (admin && (
                  <Link
                    style={{ color: 'white', textDecoration: 'none' }}
                    to='/cms'
                  >
                    CMS
                  </Link>
                ))}

              {/* <li className={layoutStyle.navigationItem}>
                DEŠAVANJA NA ZLATIBORU
              </li>
              <li className={layoutStyle.navigationItem}>
                DEŠAVANJA U OKOLINI
              </li>
              <li className={layoutStyle.navigationItem}>
                SERVISNE INFORMACIJE
              </li> */}
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Layout;
