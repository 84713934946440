import {
  SECOND_CATEGORY_FAIL,
  SECOND_CATEGORY_REQUEST,
  SECOND_CATEGORY_SUCCESS,
} from '../constants/secondCategory';

export const secondCategoryReducer = (
  state = { secondCategory: {} },
  action
) => {
  switch (action.type) {
    case SECOND_CATEGORY_REQUEST:
      return { loading: true, secondCategory: {} };
    case SECOND_CATEGORY_SUCCESS:
      return { loading: false, secondCategory: action.payload };
    case SECOND_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
