import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const CmsLoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userInfo);
  const { loading, error, userInfo } = userLogin;

  let history = useNavigate();
  useEffect(() => {
    if (userInfo?.status_code === 200) {
      history('/cms');
    }
  }, [userInfo, history]);

  const submitHandler = (e) => {
    e.preventDefault();
    // Dispatch Login
    dispatch(login(email, password));
  };

  return (
    <Form onSubmit={submitHandler}>
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}
      <Form.Group className='mb-3' controlId='email'>
        <Form.Label>Email adresa *</Form.Label>
        <Form.Control
          type='email'
          placeholder='Unesite email'
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <Form.Group className='mb-3' controlId='password'>
        <Form.Label>Password *</Form.Label>
        <Form.Control
          type='password'
          placeholder='Password'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <Button type='submit' variant='primary' className='mt-2'>
        Prijavite se
      </Button>
    </Form>
  );
};

export default CmsLoginScreen;
