import React, { useEffect } from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
  secondCategoryAllActions,
  secondCategoryDeleteActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const SecondCategoryAllScreen = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  const secondCategoryAllData = useSelector((state) => state.secondCategoryAll);
  const { loading, secondCategoryAll } = secondCategoryAllData;

  const secondCategoryDeleteData = useSelector(
    (state) => state.secondCategoryDelete
  );
  const { error, secondCategoryDelete } = secondCategoryDeleteData;

  // console.log('@@@@@@', secondCategoryAll.data);

  useEffect(() => {
    dispatch(secondCategoryAllActions());
  }, [dispatch]);

  const deleteHandler = (id) => {
    if (window.confirm('Da li ste sigurni da zelite da obrisete lokalitet?')) {
      // DELETE SECOND CATEGORY
      dispatch(secondCategoryDeleteActions(id));
      dispatch(secondCategoryAllActions());
    }
  };

  return (
    <Container>
      <Card className='my-2' style={{ textAlign: 'center' }}>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/create-second-category'>
            Kreiraj Lokalitet
          </Link>
        </Card.Body>
      </Card>

      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Naziv</th>
            <th>Prikazi sve vesti</th>
            <th>Kreiraj vest</th>
            <th>Azuriraj</th>
            <th>Obrisi</th>
          </tr>
        </thead>
        <tbody>
          {secondCategoryAll?.data &&
            secondCategoryAll.data.map((category) => (
              <tr key={category.id}>
                <td>{category.name}</td>
                <td>
                  <Link to={`/show-posts/${category.id}`}>Sve vesti</Link>
                </td>
                <td>
                  <Link
                    style={{ textDecoration: 'none', color: 'green' }}
                    to='/second-category/create-post'
                    state={{ data: category.id }}
                  >
                    Kreiraj novu vest
                  </Link>
                </td>
                <td>
                  <Link to={`/update-second-category/${category.id}`}>
                    Azuriraj
                  </Link>
                </td>
                <td>
                  {/* <Button variant='danger'>Obrisi</Button>
                   */}
                  <Button
                    variant='danger'
                    onClick={() => deleteHandler(category.id)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default SecondCategoryAllScreen;
