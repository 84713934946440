// Svi postovi za odredjenu podkategoriju za odredjeni datum
export const ALL_POST_REQUEST = 'ALL_POST_REQUEST';
export const ALL_POST_SUCCESS = 'ALL_POST_SUCCESS';
export const ALL_POST_FAIL = 'ALL_POST_FAIL';

// Svi datumi postova za odredjenu podkategoriju
export const ALL_POST_DATES_REQUEST = ' ALL_POST_DATES_REQUEST';
export const ALL_POST_DATES_SUCCESS = ' ALL_POST_DATES_SUCCESS';
export const ALL_POST_DATES_FAIL = ' ALL_POST_DATES_FAIL';

// Sve servisne informacije
export const ALL_SERVIS_INFO_REQUEST = ' ALL_SERVIS_INFO_REQUEST';
export const ALL_SERVIS_INFO_SUCCESS = ' ALL_SERVIS_INFO_SUCCESS';
export const ALL_SERVIS_INFO_FAIL = ' ALL_SERVIS_INFO_FAIL';
