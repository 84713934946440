import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../styles/MainCard.module.css';
const MainCard = ({ name, startIcon, date, description }) => {
  const location = useLocation();
  return (
    <section
      style={{ textAlign: 'center', display: location.pathname === '/' ? "flex" : 'block' }}
      className={
        styles.cardContainer + ' cp align-items-center justify-content-center'
      }
    >
      {startIcon && <img style={{ width: '35px', paddingTop: '10px', paddingBottom: '5px' }} src={startIcon} title={name} alt={name} />}

      {date && (
        <p style={{lineHeight: '1'}} className={styles.cardContent + 'text-center sub-title'}>{date.replace(' ', ' u ')} časova</p>
      )}
      <h4 style={{ padding: '0 10%' }} className={styles.cardContent + 'text-center sub-title'}>{name}</h4>
      {/* <p>{description}</p> */}
    </section>
  );
};

export default MainCard;
