import moment from 'moment';
import React from 'react';
import MainCard from './MainCard';

const PostSection = (props) => {
  const formatedDate = moment(props.date).format('DD-MM-YYYY HH:mm');

  return (
    <MainCard
      startIcon={`/api/image/${props.logo}`}
      date={formatedDate}
      name={props.title}
      description={props.description}
    />
  );
};

export default PostSection;
