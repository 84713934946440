import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { homeCategoryReducer } from './reducers/categoryReducers';
import { secondCategoryReducer } from './reducers/secondCategoryReducers';
import {
  allPostDatesReducer,
  allPostReducer,
  allServisInfoReducer,
} from './reducers/postReducers';
import {
  allPostCmsReducer,
  allPostForParentReducer,
  createServisInfoReducer,
  loginReducer,
  postCreateReducer,
  postDeleteReducer,
  postGetByIdReducer,
  postPublishReducer,
  postShowAllByCategoryReducer,
  postUnPublishedReducer,
  postUpdateReducer,
  secondCategoryAllReducer,
  secondCategoryDeleteReducer,
  secondCategoryGetByIdReducer,
  secondCategoryUpdateReducer,
} from './reducers/cmsReducers';

const reducer = combineReducers({
  majorCategory: homeCategoryReducer,
  secondCategory: secondCategoryReducer,
  allPost: allPostReducer,
  secondCategoryCreate: secondCategoryReducer,
  secondCategoryAll: secondCategoryAllReducer,
  secondCategoryGetById: secondCategoryGetByIdReducer,
  secondCategoryUpdate: secondCategoryUpdateReducer,
  secondCategoryDelete: secondCategoryDeleteReducer,
  postShowAllByCategory: postShowAllByCategoryReducer,
  postGetById: postGetByIdReducer,
  postUpdated: postUpdateReducer,
  postDeleted: postDeleteReducer,
  postCreate: postCreateReducer,
  allPostDates: allPostDatesReducer,
  allPostCms: allPostCmsReducer,
  userInfo: loginReducer,
  postPublish: postPublishReducer,
  postUnpublished: postUnPublishedReducer,
  createServisInfo: createServisInfoReducer,
  allServicesInfo: allServisInfoReducer,
  allPostForParent: allPostForParentReducer,
});

const middleware = [thunk];

const store = createStore(
  reducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
