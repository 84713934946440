import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useParams } from 'react-router-dom';
import { secondCategoryActions } from '../../actions/secondCategoryActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import MainCard from '../../components/MainCard';
import { detectLayoutColor } from '../../helpers/utils';

import styles from '../../styles/Layout.module.css';

const AroundZlatiborScreen = () => {
  const dispatch = useDispatch();

  let { id } = useParams();

  const secondCategoryData = useSelector((state) => state.secondCategory);
  const { loading, error, secondCategory } = secondCategoryData;

  const location = useLocation();
  const detectedColor = detectLayoutColor(location.pathname);

  useEffect(() => {
    dispatch(secondCategoryActions(id));
  }, [dispatch, id]);

  return (
    <section className={detectedColor + ` ${styles.layoutContent}`}>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      {secondCategory?.data?.childs &&
        secondCategory.data.childs.map((child) => (
          <Link
            style={{ textDecoration: 'none' }}
            key={child.id}
            to={`/${child.slug}/${child.id}`}
          >
            {' '}
            <MainCard
              startIcon={`/api/image/${child.logo}`}
              name={child.name}
            />{' '}
          </Link>
        ))}
    </section>
  );
};

export default AroundZlatiborScreen;
