import {
  HOME_CATEGORY_FAIL,
  HOME_CATEGORY_REQUEST,
  HOME_CATEGORY_SUCCESS,
} from '../constants/category';

export const homeCategoryReducer = (state = { majorCategory: {} }, action) => {
  switch (action.type) {
    case HOME_CATEGORY_REQUEST:
      return { loading: true, majorCategory: {} };
    case HOME_CATEGORY_SUCCESS:
      return { loading: false, majorCategory: action.payload };
    case HOME_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
