import React from 'react';
import './ToogleSwitch.css';

const ToogleSwitch = (props) => {
  const pulishUnpublish = (e) => {
    e.preventDefault();
    props.clickAction(props.postId);
  };
  return (
    <div>
      <label className='switch'>
        <input
          type='checkbox'
          checked={props.status === 'publish'}
          onChange={pulishUnpublish}
        />
        <span className='slider round'></span>
      </label>
    </div>
  );
};

export default ToogleSwitch;
