import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Layout from './components/Layout';
import { appRoutes } from './helpers/utils';
import AroundZlatiborScreen from './screens/aroundZlatibor/AroundZlatiborScreen';
import CmsHomeScreen from './screens/cms/CmsHomeScreen';
import CmsLoginScreen from './screens/cms/CmsLoginScreen';
import CreateNewPostFromSecCategoryScreen from './screens/cms/CreateNewPostFromSecCategoryScreen';
import CreateNewPostScreen from './screens/cms/CreateNewPostScreen';
import CreateServisInfoScreen from './screens/cms/CreateServisInfoScreen';
import PostCategoryScreen from './screens/cms/PostCategoryScreen';
import PostShowAllByCategoryScreen from './screens/cms/PostShowAllByCategoryScreen';
import PostUpdateScreen from './screens/cms/PostUpdateScreen';
import SecondCategoryAllScreen from './screens/cms/SecondCategoryAllScreen';
import SecondCategoryCreateScreen from './screens/cms/SecondCategoryCreateScreen';
import SecondCategoryUpdateScreen from './screens/cms/SecondCategoryUpdateScreen';
import ServisInfoAllScreen from './screens/cms/ServisInfoAllScreen';
import ServisInfoUpdateScreen from './screens/cms/ServisInfoUpdateScreen';
import HomeScreen from './screens/HomeScreen';
import CulturalCentreScreen from './screens/onZlatibor/CulturalCentreScreen';
import OnZlatiborScreen from './screens/onZlatibor/OnZlatiborScreen';
import ServisInfoScreen from './screens/servisInformation/ServisInfoScreen';
import ShowPostScreen from './screens/onZlatibor/ShowPostScreen';

import { Helmet, HelmetProvider } from 'react-helmet-async';
import ColorWrapperDetect from './components/ColorWrapperDetect';

const App = () => {
 
  return (
    <HelmetProvider>
      <Helmet>
        <title>Zlatibor live</title>
        <link rel='canonical' href='https://stage.zlatibor.live' />
      </Helmet>
      <Router>
        <Layout />
        <ColorWrapperDetect>
          <Routes>
            <Route path={appRoutes.HOME} element={<HomeScreen />} />
            {/* Servis info */}
            <Route
              path='/servisne-informacije'
              element={<ServisInfoAllScreen />}
            />
            <Route
              path='/servisne-informacije/:id'
              element={<ServisInfoScreen />}
            />
            <Route
              path='/create-servis-info'
              element={<CreateServisInfoScreen />}
            />
            <Route
              path='/update-servis-info/:id'
              element={<ServisInfoUpdateScreen />}
            />

            {/* On Zlatibor */}
            <Route
              path='/de%C5%A1avanja-na-zlatiboru/:id'
              element={<OnZlatiborScreen />}
            />
            <Route path='/show-post' element={<ShowPostScreen />} />

            {/* <Route
            path='/kulturni-centar/:id'
            element={<CulturalCentreScreen />}
          /> */}
            <Route path='/sve-vesti/:id' element={<CulturalCentreScreen />} />

            {/* Around Zlatibor */}
            <Route
              path='/de%C5%A1avanja-u-okolini/:id'
              element={<AroundZlatiborScreen />}
            />

            {/* CMS */}
            <Route path='/cms-login' element={<CmsLoginScreen />} />
            <Route path='/cms' element={<CmsHomeScreen />} />
            <Route
              path='/create-second-category'
              element={<SecondCategoryCreateScreen />}
            />
            <Route
              path='/update-second-category'
              element={<SecondCategoryAllScreen />}
            />
            <Route
              path='/update-second-category/:id'
              element={<SecondCategoryUpdateScreen />}
            />
            <Route
              path='/second-category/create-post'
              element={<CreateNewPostFromSecCategoryScreen />}
            />

            <Route
              path='/show-post-category'
              element={<PostCategoryScreen />}
            />
            <Route
              path='/show-posts/:id'
              element={<PostShowAllByCategoryScreen />}
            />
            <Route path='/posts/:id' element={<PostUpdateScreen />} />
            <Route path='/create-new-post' element={<CreateNewPostScreen />} />
          </Routes>
        </ColorWrapperDetect>
      </Router>
    </HelmetProvider>
  );
};

export default App;
