import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import pell from 'pell';
import { useNavigate } from 'react-router-dom';
import {
  postCreateActions,
  secondCategoryAllActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { homeCategoryActions } from '../../actions/categoryActions';
import { secondCategoryActions } from '../../actions/secondCategoryActions';
import PostSection from '../../components/PostSection';
import moment from 'moment';

const CreateNewPostScreen = () => {
  const [imageMessage, setImageMessage] = useState(null);
  const initEditor = () => {
    pell.init({
      element: document.getElementById('custom-editor'),
      onChange: (html) => setDescription(html),
      defaultParagraphSeparator: 'div',
      styleWithCSS: false,
      actions: [
        'bold',
        'underline',
        'italic',
        'link',
        'heading1',
        'heading2',
        'olist',
      ],
    });
  };

  useEffect(() => {
    initEditor();
  }, []);

  const convertTo64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const dispatch = useDispatch();

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  const [post, setPost] = useState('');
  const [description, setDescription] = useState('');
  const [created_at, setCreatedAt] = useState('');
  const [major_category_id, setMajorCategory] = useState('');
  let category_ids = [];
  const [message, setMessage] = useState(null);
  const [startDate, setStartDate] = useState(new Date());
  let [picture_name, setPictureName] = useState('');
  const [picture, setPicture] = useState(null);

  const secondCategoryAllData = useSelector((state) => state.secondCategoryAll);
  const { secondCategoryAll } = secondCategoryAllData;

  useEffect(() => {
    dispatch(secondCategoryAllActions());
  }, [dispatch]);

  const addItemOrRemove = (item) => {
    if (category_ids.indexOf(item) !== -1) {
      category_ids.splice(item, 1);
      return;
    } else {
      category_ids.push(item);
    }
  };

  const postCreateData = useSelector((state) => state.postCreate);
  const { error, loading, postCreate } = postCreateData;

  useEffect(() => {
    if (postCreate?.status_code === 200) {
      setMessage('Post je uspesno kreiran!');
    }
  }, [postCreate]);

  // Tri glavne
  const majorCategoryData = useSelector((state) => state.majorCategory);
  const { majorCategory } = majorCategoryData;

  useEffect(() => {
    dispatch(homeCategoryActions());
  }, [dispatch]);

  // Svi lokaliteti
  const secondCategoryData = useSelector((state) => state.secondCategory);
  const { secondCategory } = secondCategoryData;

  useEffect(() => {
    if (major_category_id) {
      dispatch(secondCategoryActions(major_category_id));
    }
  }, [dispatch, major_category_id]);

  let formatedTime;
  let imageNameSplit;
  const submitHandler = (e) => {
    e.preventDefault();

    imageNameSplit = picture_name.split('\\');
    imageNameSplit = imageNameSplit[imageNameSplit.length - 1].split('.');
    imageNameSplit = imageNameSplit[0];
    picture_name = imageNameSplit;

    formatedTime = moment(startDate).format('YYYY-MM-DD HH:mm');

    dispatch(
      postCreateActions(
        post,
        category_ids,
        description,
        formatedTime,
        picture_name,
        picture
      )
    );
    history('/show-post-category');
    setPost('');
    setCreatedAt('');
  };

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className='mb-3' controlId='post'>
        <Form.Label>Naziv Posta *</Form.Label>
        <Form.Control
          type='text'
          placeholder='Unesite naziv posta'
          value={post}
          onChange={(e) => setPost(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <div style={{ background: 'white' }} id='custom-editor'></div>
      {/* <FloatingLabel controlId="description" label="Unesite opis posta">
        <Form.Control
          as="textarea"
          placeholder="Unesite opis posta"
          style={{ height: "50vh" }}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </FloatingLabel> */}

      <Form.Group className='mb-3' controlId='created_at'>
        <Form.Label>Datum i vreme vesti *</Form.Label>
        {/* <Form.Control
          type='date'
          value={created_at}
          onChange={(e) => setCreatedAt(e.target.value)}
        /> */}

        {/* <DatePicker
          // onChange={(event) =>
          //   handleFormChange(index, event, 'start')
          // }
          showTimeSelect
          dateFormat='Pp'
          name='start'
        /> */}

        <DatePicker
          selected={startDate}
          onChange={(e) => setStartDate(e)}
          showTimeSelect
          dateFormat='Pp'
        />
      </Form.Group>

      <Form.Group className='mb-3' controlId='imageName'>
        <Form.Label>Slika *</Form.Label>
        <Form.Control
          type='file'
          value={picture_name}
          onChange={async (e) => {
            let imageNameData = e.target.value;
            setPictureName(imageNameData);
            if (e.target.files[0].size > 8000000) {
              setImageMessage('Slika mora biti ispod 8 mb');
            } else {
              const base = await convertTo64(e.target.files[0]);
              setPicture(base);
              setImageMessage(null);
            }
          }}
        />
      </Form.Group>

      <Form.Group className='mb-3' controlId='major_category'>
        <Form.Label>Izaberite glavnu kategoriju</Form.Label>
        <Form.Select
          name='major_category'
          value={major_category_id}
          onChange={(e) => setMajorCategory(e.target.value)}
        >
          <option>Odaberi glavnu kategoriju</option>
          {majorCategory?.data &&
            majorCategory.data.map((category) => (
              <>
                {category.name !== 'SERVISNE INFORMACIJE' && (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                )}
              </>
            ))}
        </Form.Select>
      </Form.Group>

      {secondCategory?.data?.childs?.length > 0 && (
        <Form.Group className='mb-3' controlId='category_ids'>
          <Form.Label>Izaberi kategoriju</Form.Label>
          <Form.Select
            name='category_ids'
            value={category_ids}
            onChange={(e) => {
              addItemOrRemove(e.target.value);
            }}
            multiple
          >
            {secondCategory?.data?.childs.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
      {message && !error && <Message variant='success'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <div>
        <h3>Prikaz</h3>
        <PostSection
          date={moment(startDate).format('YYYY-MM-DD HH:mm')}
          title={post}
          description={description}
        />
      </div>

      {imageMessage && <Message variant='danger'>{imageMessage}</Message>}

      <Button type='submit' variant='primary' className='mt-2'>
        Kreiraj Vest
      </Button>
    </Form>
  );
};

export default CreateNewPostScreen;
