import React, { useState, useEffect } from 'react';
import { Button, Container, Form, Image } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  secondCategoryGetByIdActions,
  secondCategoryUpdateActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const SecondCategoryUpdateScreen = () => {
  const [name, setName] = useState('');
  const [parent_id, setParentId] = useState('');
  let [logoName, setLogoName] = useState('');
  const [logo, setLogo] = useState(null);
  let [imageName, setImageName] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState(null);
  const [imageMessage, setImageMessage] = useState(null);

  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  const convertTo64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  // Vrati kategoriju za odredjeni id
  const dispatch = useDispatch();

  let { id } = useParams();

  const secondCategoryGetByIdData = useSelector(
    (state) => state.secondCategoryGetById
  );
  const { secondCategoryGetById } = secondCategoryGetByIdData;

  const secondCategoryUpdateData = useSelector(
    (state) => state.secondCategoryUpdate
  );
  const { loading, error, secondCategoryUpdate } = secondCategoryUpdateData;

  // console.log('ID', secondCategoryGetById);

  useEffect(() => {
    dispatch(secondCategoryGetByIdActions(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (secondCategoryGetById?.data?.name) {
      setName(secondCategoryGetById?.data?.name);
      setParentId(secondCategoryGetById?.data?.parent_id);
    }
  }, [secondCategoryGetById]);

  let logoNameSplit;
  let imageNameSplit;
  const submitHandler = (e) => {
    e.preventDefault();

    logoNameSplit = logoName.split('\\');
    logoNameSplit = logoNameSplit[logoNameSplit.length - 1].split('.');
    logoNameSplit = logoNameSplit[0];
    logoName = logoNameSplit;

    imageNameSplit = imageName.split('\\');
    imageNameSplit = imageNameSplit[imageNameSplit.length - 1].split('.');
    imageNameSplit = imageNameSplit[0];
    imageName = imageNameSplit;

    dispatch(
      secondCategoryUpdateActions(
        id,
        name,
        parent_id,
        logoName,
        logo,
        imageName,
        image
      )
    );
    setMessage('Podkategorija je uspesno editovana!');
  };

  return (
    <Container>
      <Form onSubmit={submitHandler}>
        {loading && <Loader />}
        {message && <Message variant='success'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}

        <Form.Group className='mb-3' controlId='name'>
          <Form.Label>Naziv lokaliteta *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Unesite naziv kategorije'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='logoName'>
          <Form.Label>Logo *</Form.Label>
          <br />
          <Image
            src={
              secondCategoryGetById?.data?.logo
                ? `/api/image/${secondCategoryGetById?.data?.logo}`
                : ''
            }
            alt='LOGO'
          />
          <Form.Control
            type='file'
            value={logoName}
            onChange={async (e) => {
              let imageName = e.target.value;
              setLogoName(imageName);
              if (e.target.files[0].size > 8000000) {
                setImageMessage('Slika mora biti ispod 8 mb');
              } else {
                const base = await convertTo64(e.target.files[0]);
                setLogo(base);
                setImageMessage(null);
              }
            }}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='imageName'>
          <Form.Label>Slika *</Form.Label>
          <br />
          <Image
            style={{ maxWidth: '100%' }}
            src={
              secondCategoryGetById?.data?.image
                ? `/api/image/${secondCategoryGetById?.data?.image}`
                : ''
            }
            alt='Slika'
          />
          <Form.Control
            type='file'
            value={imageName}
            onChange={async (e) => {
              let imageNameData = e.target.value;
              setImageName(imageNameData);

              if (e.target.files[0].size > 8000000) {
                setImageMessage('Slika mora biti ispod 8 mb');
              } else {
                const base = await convertTo64(e.target.files[0]);
                setImage(base);
                setImageMessage(null);
              }
            }}
          />
        </Form.Group>

        {imageMessage && <Message variant='danger'>{imageMessage}</Message>}

        <Button variant='primary' type='submit' className='mt-2'>
          Azuriraj lokalitet
        </Button>
      </Form>
    </Container>
  );
};

export default SecondCategoryUpdateScreen;
