import axios from 'axios';
import {
  ALL_POST_DATES_FAIL,
  ALL_POST_DATES_REQUEST,
  ALL_POST_DATES_SUCCESS,
  ALL_POST_FAIL,
  ALL_POST_REQUEST,
  ALL_POST_SUCCESS,
  ALL_SERVIS_INFO_FAIL,
  ALL_SERVIS_INFO_REQUEST,
  ALL_SERVIS_INFO_SUCCESS,
} from '../constants/post';

export const allPostActions = (id, created_at) => async (dispatch) => {
  try {
    dispatch({ type: ALL_POST_REQUEST });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `/api/categories/posts`,
      { id, created_at },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: ALL_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_POST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allPostDatesActions = (id) => async (dispatch) => {
  try {
    dispatch({ type: ALL_POST_DATES_REQUEST });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(
      `/api/categories/post-dates`,
      { id },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: ALL_POST_DATES_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_POST_DATES_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allServiceInfoActions = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_SERVIS_INFO_REQUEST });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(`/api/get-service-info`, {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: ALL_SERVIS_INFO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: ALL_SERVIS_INFO_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
