import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { homeCategoryActions } from '../actions/categoryActions';
import { Link, useLocation } from 'react-router-dom';
import { detectLayoutColor } from '../helpers/utils';
import styles from '../styles/Layout.module.css';
import MainCard from '../components/MainCard';

const HomeScreen = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const detectedColor = detectLayoutColor(location.pathname);

  const majorCategoryData = useSelector((state) => state.majorCategory);
  const { majorCategory } = majorCategoryData;

  useEffect(() => {
    dispatch(homeCategoryActions());
  }, [dispatch]);

  return (
    <div className={detectedColor + ` ${styles.layoutContent}`}>
      {majorCategory?.data &&
        majorCategory.data.reverse().map((category) => (
          <Link
          style={{ textDecoration: 'none' }}
          key={category.id}
          to={category.name === 'SERVISNE INFORMACIJE' ? `/servisne-informacije/${category.id}` : `/sve-vesti/${category.id}`}
          >
            <MainCard name={category.name} />
          </Link>
        ))}
    </div>
  );
};

export default HomeScreen;
