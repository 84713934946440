import React from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../../styles/MainCard.module.css';
import moment from "moment";

const ShowPostScreen = () => {
  const location = useLocation();

  const data = location.state?.data;
  // console.log('POST DATA', data);
  let startIcon = data.categories[0].logo;
  return (
    <div className='post-content' style={{ background: '#ebeae9', padding: 30 }}>
    <section
      style={{ textAlign: 'center', paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}
      className={
        styles.cardContainer + ' cp align-items-center justify-content-center'
      }
    >
      {startIcon && (
        <img
          className={styles.cardContentTwo}
          src={`/api/image/${startIcon}`}
          title={data.categories[0].name}
          alt={data.categories[0].name}
        />
      )}
      {data.post_datetime && (
        <h2 className={styles.cardContent + 'text-center sub-title small'}>
          {moment(data.post_datetime).format('YYYY-MM-DD HH:mm').replace(' ', ' u ')} časova
        </h2>
      )}
      <h2 className={styles.cardContent + 'text-center sub-title small'}>
        {data.title}
      </h2>
      <div
      style={{ textAlign: 'left', marginTop: '7px' }}
        className={styles.cardContent + 'text-center sub-title small inline_html_content' }
        dangerouslySetInnerHTML={{ __html: data.description }}
      ></div>
    </section>
    </div>
  );
};

export default ShowPostScreen;
