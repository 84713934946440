import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { homeCategoryActions } from '../../actions/categoryActions';
import {
  allPostCmsActions,
  postDeleetActions,
  postPublishActions,
  postUnpublishedActions,
  secondCategoryAllActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import ToogleSwitch from '../../components/ToogleSwitch';

const PostCategoryScreen = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  // Paginacija
  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  // Filter
  const [localitet_id, setLocalitetId] = useState('');
  const [category_id, setCategoryId] = useState('');

  const allPostCmsData = useSelector((state) => state.allPostCms);
  const { loading, error, allPostCms } = allPostCmsData;

  useEffect(() => {
    dispatch(allPostCmsActions(page));
  }, [dispatch, page]);

  useEffect(() => {
    if (allPostCms?.data) {
      setFirstPage('1');
      setPreviousPage(allPostCms?.data?.prev_page_url);
      setLastPage(allPostCms?.data?.last_page);
      setNextPage(allPostCms?.data?.next_page_url);
      setCurrentPage(allPostCms?.data?.current_page);
    }
  }, [allPostCms]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    dispatch(allPostCmsActions(page, localitet_id, category_id));
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    dispatch(allPostCmsActions(page, localitet_id, category_id));
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    dispatch(allPostCmsActions(page, localitet_id, category_id));
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    allPostCmsActions(page, localitet_id, category_id);
  };

  // Publish
  const postPublishData = useSelector((state) => state.postPublish);
  const { postPublish } = postPublishData;

  const publishHandler = (postId) => {
    dispatch(postPublishActions(postId));
    dispatch(allPostCmsActions());
  };

  // Unpublished
  const postUnpublishedData = useSelector((state) => state.postUnpublished);
  const { postUnpublished } = postUnpublishedData;

  // Get all Lokalitet
  const secondCategoryAllData = useSelector((state) => state.secondCategoryAll);
  const { secondCategoryAll } = secondCategoryAllData;

  useEffect(() => {
    dispatch(secondCategoryAllActions());
  }, [dispatch]);

  // Get all major Category
  const majorCategoryData = useSelector((state) => state.majorCategory);
  const { majorCategory } = majorCategoryData;

  useEffect(() => {
    dispatch(homeCategoryActions());
  }, [dispatch]);

  const unpublishedHandler = (postId) => {
    dispatch(postUnpublishedActions(postId));
    dispatch(allPostCmsActions());
  };

  // console.log('allPostCms', allPostCms);
  const deleteHandler = (post_id) => {
    if (window.confirm('Da li ste sigurni da zelite da obrisete vest?')) {
      // DELETE SECOND CATEGORY
      dispatch(postDeleetActions(post_id));
      dispatch(allPostCmsActions());
    }
  };

  // Filter Handler
  const filterHandler = (e) => {
    e.preventDefault();
    let page = 1;
    setPage(page);
    dispatch(allPostCmsActions(page, localitet_id, category_id));
  };

  return (
    <Container>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      <Card className='my-2' style={{ textAlign: 'center' }}>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/create-new-post'>
            Kreiraj novu vest
          </Link>
        </Card.Body>
      </Card>

      <Form onSubmit={filterHandler}>
        <Row>
          <Col>
            <Form.Label htmlFor='localitet_id'>Lokalitet</Form.Label>
            <Form.Select
              name='localitet_id'
              value={localitet_id}
              onChange={(e) => setLocalitetId(e.target.value)}
            >
              <option value=''>Izaberite Lokalitet</option>
              {secondCategoryAll?.data &&
                secondCategoryAll.data.map((lokalitet) => (
                  <option key={lokalitet.id} value={lokalitet.id}>
                    {lokalitet.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
          <Col>
            <Form.Label htmlFor='category_id'>Kategorija</Form.Label>
            <Form.Select
              name='category_id'
              value={category_id}
              onChange={(e) => setCategoryId(e.target.value)}
            >
              <option value=''>Izaberite Kategoriju</option>
              {majorCategory?.data &&
                majorCategory.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Button type='submit' variant='primary' className='mt-3 my-2'>
            Pretrazi
          </Button>
        </Row>
      </Form>

      <Table>
        <thead>
          <tr>
            <th>Naziv</th>
            <th>Publish/Unpublish</th>
            <th>Obrisi</th>
          </tr>
        </thead>
        <tbody>
          {allPostCms?.data?.data.length > 0 &&
            allPostCms?.data?.data.map((post) => {
              if (post?.categories[0]?.slug === 'servisne-informacije') {
                // console.log('"servisne-informacije"');
                return null;
              }
              return (
                <tr key={post.id}>
                  <td>
                    <Link
                      to={`/posts/${post.id}`}
                      style={{ textDecoration: 'none', color: 'green' }}
                    >
                      {post.title}
                    </Link>
                  </td>
                  <td>
                    {/* {post.status === 'publish' ? (
                    <Button
                      variant='info'
                      onClick={() => unpublishedHandler(post.id)}
                    >
                      <i className='fa-solid fa-delete-left'></i>
                    </Button>
                  ) : (
                    <Button
                      variant='info'
                      onClick={() => publishHandler(post.id)}
                    >
                      <i className='fa fa-upload' aria-hidden='true'></i>
                    </Button>
                  )} */}
                    <ToogleSwitch
                      status={post.status}
                      postId={post.id}
                      clickAction={
                        post.status === 'publish'
                          ? unpublishedHandler
                          : publishHandler
                      }
                    />
                  </td>
                  <td>
                    <Button
                      variant='danger'
                      onClick={() => deleteHandler(post.id)}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>

      <Pagination className='justify-content-center mb-4 my-5'>
        <Pagination.First onClick={paginationFirstHandler} />
        <Pagination.Prev onClick={paginationPrevHandler} />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item disabled>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={paginationNextHandler} />
        <Pagination.Last onClick={paginationLastHandler} />
      </Pagination>
    </Container>
  );
};

export default PostCategoryScreen;
