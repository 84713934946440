import React, { useEffect, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import pell from 'pell';
import {
  postGetByIdActions,
  postUpdateActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import moment from 'moment';

const ServisInfoUpdateScreen = () => {
  const dispatch = useDispatch();
  const [post_id, setPostId] = useState('');
  const [title, setTitle] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');
  let picture_name = 'iboy9P-dom kulture cajetina.jpeg';
  const picture = null;

  const [message, setMessage] = useState(null);

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();

  const initEditor = () => {
    const editor = pell.init({
      element: document.getElementById('update-editor'),
      onChange: (html) => setDescription(html),
      defaultParagraphSeparator: 'div',
      styleWithCSS: false,
      actions: [
        'bold',
        'underline',
        'italic',
        'link',
        'heading1',
        'heading2',
        'olist',
      ],
    });
    return editor;
  };

  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  let { id } = useParams();

  const postGetByIdData = useSelector((state) => state.postGetById);
  const { loading, error, postGetById } = postGetByIdData;

  useEffect(() => {
    dispatch(postGetByIdActions(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (postGetById?.data?.title) {
      setTitle(postGetById?.data?.title);
      setDescription(postGetById?.data?.description);
      let editorT = document.getElementById('update-editor');
      if (editorT.children.length === 0) {
        const editor = initEditor();
        editor.content.innerHTML = postGetById?.data?.description;
      }
      setDate(postGetById?.data?.created_at);
      setPostId(postGetById?.data?.id);
    }
  }, [postGetById]);

  let formatedTime = moment(new Date()).format('DD-MM-YYYY HH:mm');

  const submitHandler = (e) => {
    e.preventDefault();

    dispatch(
      postUpdateActions(
        post_id,
        title,
        picture_name,
        formatedTime,
        picture,
        description
      )
    );
    setMessage('Servisna informacija je uspesno editovana!');
    history('/servisne-informacije');
  };

  return (
    <Container>
      <Form onSubmit={submitHandler}>
        {loading && <Loader />}
        {message && <Message variant='success'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        <Form.Group className='mb-3' controlId='title'>
          <Form.Label>Naslov *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Unesite naziv servisne informacije'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>

        <div style={{ background: 'white' }} id='update-editor'></div>

        <Button variant='primary' type='submit' className='mt-2'>
          Azuriraj servisnu informaciju
        </Button>
      </Form>
    </Container>
  );
};

export default ServisInfoUpdateScreen;
