import React, { useEffect } from 'react';
import { Card, Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

const CmsHomeScreen = () => {
  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);
  return (
    <Container>
      <Card className='my-3'>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/update-second-category'>
            Lokaliteti
          </Link>
        </Card.Body>
      </Card>
      <Card className='my-3'>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/show-post-category'>
            Vesti
          </Link>
        </Card.Body>
      </Card>
      <Card className='my-3'>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/servisne-informacije'>
            Servisne informacije
          </Link>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CmsHomeScreen;
