import axios from 'axios';
import {
  HOME_CATEGORY_FAIL,
  HOME_CATEGORY_REQUEST,
  HOME_CATEGORY_SUCCESS,
} from '../constants/category';

export const homeCategoryActions = () => async (dispatch) => {
  try {
    dispatch({ type: HOME_CATEGORY_REQUEST });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.get('/api/categories', {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: HOME_CATEGORY_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: HOME_CATEGORY_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
