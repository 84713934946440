export const detectLayoutColor = (routeName) => {
  let detectedColor = "bg-gray";

  if(routeName.includes('servisne-informacije')) {
    detectedColor = "bg-green";
  }

  switch (routeName) {
    case "/":
      detectedColor = "bg-green";
      break;

    default:
      break;
  }
  return detectedColor;
};

export const appRoutes = Object.freeze({
  HOME: "/",
  SERVICES_INFORMATION: '/servisne-informacije'
});
