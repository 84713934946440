import React, { useEffect, useState } from 'react';
import { Button, Container, Form, Image } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import pell from 'pell';
import {
  postGetByIdActions,
  postUpdateActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import moment from 'moment';

const PostUpdateScreen = () => {
  const dispatch = useDispatch();
  const [post_id, setPostId] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(new Date());
  let [picture_name, setPictureName] = useState('');
  const [picture, setPicture] = useState(null);
  const [message, setMessage] = useState(null);
  const [imageMessage, setImageMessage] = useState(null);

  const [isDateChange, setIsDateChange] = useState(false);

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  const convertTo64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  let history = useNavigate();

  const initEditor = () => {
    const editor = pell.init({
      element: document.getElementById('update-editor'),
      onChange: (html) => setDescription(html),
      defaultParagraphSeparator: 'div',
      styleWithCSS: false,
      actions: [
        'bold',
        'underline',
        'italic',
        'link',
        'heading1',
        'heading2',
        'olist',
      ],
    });
    return editor;
  };

  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  let { id } = useParams();

  const postGetByIdData = useSelector((state) => state.postGetById);
  const { loading, error, postGetById } = postGetByIdData;

  useEffect(() => {
    dispatch(postGetByIdActions(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (postGetById?.data?.title) {
      let editorT = document.getElementById('update-editor');
      if (editorT.children.length === 0) {
        const editor = initEditor();
        editor.content.innerHTML = postGetById?.data?.description;
      }
      setTitle(postGetById?.data?.title);
      setStartDate(new Date(postGetById?.data?.post_datetime));
      setDescription(postGetById?.data?.description);
      setPostId(postGetById?.data?.id);
    }
  }, [postGetById]);

  let formatedTime;
  let imageNameSplit;
  const submitHandler = (e) => {
    e.preventDefault();
    imageNameSplit = picture_name.split('\\');
    imageNameSplit = imageNameSplit[imageNameSplit.length - 1].split('.');
    imageNameSplit = imageNameSplit[0];
    picture_name = imageNameSplit;
    if (isDateChange) {
      formatedTime = moment(startDate).format('YYYY-MM-DD HH:mm');
    } else {
      formatedTime = postGetById?.data?.post_datetime;
    }

    dispatch(
      postUpdateActions(
        post_id,
        title,
        picture_name,
        formatedTime,
        picture,
        description
      )
    );
    setMessage('Vest je uspesno editovana!');
    history('/show-post-category');
  };

  return (
    <Container>
      <Form onSubmit={submitHandler}>
        {loading && <Loader />}
        {message && <Message variant='success'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        <Form.Group className='mb-3' controlId='title'>
          <Form.Label>Naslov *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Unesite naziv posta'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Group>
        <Form.Group className='mb-3' controlId='date'>
          <Form.Label>Datum kreiranja</Form.Label>
          {/* <Form.Control
            type='date'
            placeholder='Unesite datum'
            value={date}
            onChange={(e) => setDate(e.target.value)}
          /> */}
          <DatePicker
            selected={startDate}
            onChange={(e) => {
              setStartDate(e);
              setIsDateChange(true);
            }}
            showTimeSelect
            dateFormat='Pp'
            value={startDate}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='imageName'>
          <Form.Label>Slika *</Form.Label>
          <br />
          <Image
            style={{ maxWidth: '100%' }}
            src={
              postGetById?.data?.picture_name
                ? `/api/image/${postGetById?.data?.picture_name}`
                : ''
            }
            alt='Slika'
          />
          <Form.Control
            type='file'
            value={picture_name}
            onChange={async (e) => {
              let imageNameData = e.target.value;
              setPictureName(imageNameData);
              if (e.target.files[0].size > 8000000) {
                setImageMessage('Slika mora biti ispod 8 mb');
              } else {
                const base = await convertTo64(e.target.files[0]);
                setPicture(base);
                setImageMessage(null);
              }
            }}
          />
        </Form.Group>

        <div style={{ background: 'white' }} id='update-editor'></div>
        {/* <Form.Group className='mb-3' controlId='description'>
          <Form.Label>Tekst posta</Form.Label>
          <Form.Control
            as='textarea'
            rows={3}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </Form.Group> */}
        {imageMessage && <Message variant='danger'>{imageMessage}</Message>}
        <Button variant='primary' type='submit' className='mt-2'>
          Azuriraj vest
        </Button>
      </Form>
    </Container>
  );
};

export default PostUpdateScreen;
