import axios from 'axios';
import {
  ALL_POST_CMS_FAIL,
  ALL_POST_CMS_REQUEST,
  ALL_POST_CMS_SUCCESS,
  ALL_POST_FOR_PARENT_FAIL,
  ALL_POST_FOR_PARENT_REQUEST,
  ALL_POST_FOR_PARENT_SUCCESS,
  ALL_POST_FOR_SECOND_CATEGORY_FAIL,
  ALL_POST_FOR_SECOND_CATEGORY_REQUEST,
  ALL_POST_FOR_SECOND_CATEGORY_SUCCESS,
  CREATE_SERVIS_INFO_FAIL,
  CREATE_SERVIS_INFO_REQUEST,
  CREATE_SERVIS_INFO_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  POST_CREATE_FAIL,
  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_DELETE_FAIL,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_GET_BY_ID_FAIL,
  POST_GET_BY_ID_REQUEST,
  POST_GET_BY_ID_SUCCESS,
  POST_PUBLISH_FAIL,
  POST_PUBLISH_REQUEST,
  POST_PUBLISH_SUCCESS,
  POST_UNPUBLISHED_FAIL,
  POST_UNPUBLISHED_REQUEST,
  POST_UNPUBLISHED_SUCCESS,
  POST_UPDATE_FAIL,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  SECOND_CATEGORY_ALL_FAIL,
  SECOND_CATEGORY_ALL_REQUEST,
  SECOND_CATEGORY_ALL_SUCCESS,
  SECOND_CATEGORY_CREATE_FAIL,
  SECOND_CATEGORY_CREATE_REQUEST,
  SECOND_CATEGORY_CREATE_SUCCESS,
  SECOND_CATEGORY_DELETE_FAIL,
  SECOND_CATEGORY_DELETE_REQUEST,
  SECOND_CATEGORY_DELETE_SUCCESS,
  SECOND_CATEGORY_GET_BY_ID_FAIL,
  SECOND_CATEGORY_GET_BY_ID_REQUEST,
  SECOND_CATEGORY_GET_BY_ID_SUCCESS,
  SECOND_CATEGORY_UPDATE_FAIL,
  SECOND_CATEGORY_UPDATE_REQUEST,
  SECOND_CATEGORY_UPDATE_SUCCESS,
} from '../constants/cms';

export const secondCategoryCreateActions =
  (name, parent_id, logo_name, logo, image_name, image) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SECOND_CATEGORY_CREATE_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/categories`,
        { name, parent_id, logo_name, logo, image_name, image },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: SECOND_CATEGORY_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SECOND_CATEGORY_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const secondCategoryAllActions = () => async (dispatch, getState) => {
  try {
    dispatch({ type: SECOND_CATEGORY_ALL_REQUEST });

    const {
      userInfo: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };

    const { data } = await axios.get(
      '/api/categories/all-childs-categories',
      {},
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: SECOND_CATEGORY_ALL_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: SECOND_CATEGORY_ALL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const secondCategoryGetByIdActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SECOND_CATEGORY_GET_BY_ID_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.get(`/api/categories/one/${id}`, {}, config);

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: SECOND_CATEGORY_GET_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SECOND_CATEGORY_GET_BY_ID_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const secondCategoryUpdateActions =
  (id, name, parent_id, logoName, logo, imageName, image) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: SECOND_CATEGORY_UPDATE_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/categories/${id}`,
        {
          name,
          parent_id,
          logoName,
          logo,
          imageName,
          image,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: SECOND_CATEGORY_UPDATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SECOND_CATEGORY_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const secondCategoryDeleteActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: SECOND_CATEGORY_DELETE_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.delete(`/api/categories/${id}`, {}, config);

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.status_code === 400) {
        throw new Error(data.data);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: SECOND_CATEGORY_DELETE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: SECOND_CATEGORY_DELETE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postShowAllByCategoryActions =
  (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ALL_POST_FOR_SECOND_CATEGORY_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(`/api/posts/category`, { id }, config);

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: ALL_POST_FOR_SECOND_CATEGORY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ALL_POST_FOR_SECOND_CATEGORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postGetByIdActions = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: POST_GET_BY_ID_REQUEST });

    const {
      userInfo: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };

    const { data } = await axios.get(`/api/posts/${id}`, {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: POST_GET_BY_ID_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_GET_BY_ID_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const postUpdateActions =
  (id, title, picture_name, post_datetime, picture, description) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: POST_UPDATE_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/posts/${id}`,
        {
          title,
          picture_name,
          post_datetime,
          picture,
          description,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: POST_UPDATE_SUCCESS, payload: data });
      dispatch({ type: POST_GET_BY_ID_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_UPDATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postDeleetActions = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: POST_DELETE_REQUEST });

    const {
      userInfo: { userInfo },
    } = getState();

    const config = {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${userInfo.data}`,
      },
    };

    const { data } = await axios.delete(`/api/posts/${id}`, {}, config);

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.error) {
      throw new Error(data.error);
    }

    dispatch({ type: POST_DELETE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: POST_DELETE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const allPostCmsActions =
  (page, localitet_id, category_id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ALL_POST_CMS_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        'api/posts/all',
        { page, localitet_id, category_id },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: ALL_POST_CMS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ALL_POST_CMS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({
      type: LOGIN_REQUEST,
    });

    const config = {
      headers: {
        'Content-type': 'application/json',
      },
    };

    const { data } = await axios.post(
      '/api/login',
      {
        email,
        password,
      },
      config
    );

    if (data.status_code === 422) {
      let errors = Object.values(data.errors);
      throw new Error(errors);
    }

    if (data.status_code === 400) {
      throw new Error(data.data);
    }

    dispatch({
      type: LOGIN_SUCCESS,
      payload: data,
    });

    localStorage.setItem('userInfo', JSON.stringify(data));
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const logout = () => (dispatch) => {
  localStorage.removeItem('userInfo');
  dispatch({ type: LOGOUT });
};

export const postCreateActions =
  (title, category_ids, description, post_datetime, picture_name, picture) =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: POST_CREATE_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/posts`,
        {
          title,
          category_ids,
          description,
          post_datetime,
          picture_name,
          picture,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: POST_CREATE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_CREATE_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postPublishActions =
  (id, status = 'publish') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: POST_PUBLISH_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/posts/publish-unpublish`,
        {
          id,
          status,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: POST_PUBLISH_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_PUBLISH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const postUnpublishedActions =
  (id, status = 'unpublished') =>
  async (dispatch, getState) => {
    try {
      dispatch({ type: POST_UNPUBLISHED_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/posts/publish-unpublish`,
        {
          id,
          status,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: POST_UNPUBLISHED_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: POST_UNPUBLISHED_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const createServisInfoActions =
  (title, description, created_at) => async (dispatch, getState) => {
    try {
      dispatch({ type: CREATE_SERVIS_INFO_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        `/api/service-info`,
        {
          title,
          description,
          // created_at,
        },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: CREATE_SERVIS_INFO_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: CREATE_SERVIS_INFO_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// Novi zahtev
export const allPostForParentActions =
  (page, id) => async (dispatch, getState) => {
    try {
      dispatch({ type: ALL_POST_FOR_PARENT_REQUEST });

      const {
        userInfo: { userInfo },
      } = getState();

      const config = {
        headers: {
          'Content-type': 'application/json',
          Authorization: `Bearer ${userInfo.data}`,
        },
      };

      const { data } = await axios.post(
        '/api/categories/child-categories-posts-for-parent-category',
        { page, parent_id: id },
        config
      );

      if (data.status_code === 422) {
        let errors = Object.values(data.errors);
        throw new Error(errors);
      }

      if (data.error) {
        throw new Error(data.error);
      }

      dispatch({ type: ALL_POST_FOR_PARENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ALL_POST_FOR_PARENT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
