import React, { useEffect, useState } from 'react';
import pell from 'pell';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { createServisInfoActions } from '../../actions/cmsActions';
import Message from '../../components/Message';
import Loader from '../../components/Loader';

const CreateServisInfoScreen = () => {
  const dispatch = useDispatch();

  const initEditor = () => {
    pell.init({
      element: document.getElementById('custom-editor'),
      onChange: (html) => setDescription(html),
      defaultParagraphSeparator: 'div',
      styleWithCSS: false,
      actions:  ['bold', 'underline', 'italic', 'link', 'heading1', 'heading2', 'olist']
    });
  };

  useEffect(() => {
    initEditor();
  }, []);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const created_at = new Date();
  const [message, setMessage] = useState(null);

  const createServisInfoData = useSelector((state) => state.createServisInfo);
  const { error, loading, createServisInfo } = createServisInfoData;

  useEffect(() => {
    if (createServisInfo?.status_code === 200) {
      setMessage('Servisna informacija je uspesno kreiran!');
      setTitle('');
    }
  }, [createServisInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(createServisInfoActions(title, description, created_at));
  };

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className='mb-3' controlId='post'>
        <Form.Label>Naslov servisne informacije *</Form.Label>
        <Form.Control
          type='text'
          placeholder='Unesite naslov servisne informacije'
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <Form.Label>Sadrzaj servisne informacije *</Form.Label>
      <div
        style={{ height: '70vh', background: 'white' }}
        id='custom-editor'
      ></div>

      {message && <Message variant='success'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <Button type='submit' variant='primary' className='mt-2'>
        Kreiraj servisnu informaciju
      </Button>
    </Form>
  );
};

export default CreateServisInfoScreen;
