import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { allServiceInfoActions } from '../../actions/postActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import ServisInfoCom from '../../components/ServisInfoCom';
import { detectLayoutColor } from '../../helpers/utils';
import styles from '../../styles/Layout.module.css';

const ServisInfoScreen = () => {
  const dispatch = useDispatch();

  const allServicesInfoData = useSelector((state) => state.allServicesInfo);
  const { loading, error, allServicesInfo } = allServicesInfoData;

  const location = useLocation();
  const detectedColor = detectLayoutColor(location.pathname);

  useEffect(() => {
    dispatch(allServiceInfoActions());
  }, [dispatch]);

  return (
    <div
      className={
        detectedColor +
        ` ${styles.layoutContent} scroll-content-service-information`
      }
    >
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      {allServicesInfo?.data?.posts &&
        allServicesInfo.data.posts.map((info) => (
          <div key={info.id}>
          <ServisInfoCom
            title={info.title}
            description={info.description}
          />
          <hr style={{ background: 'black', height: '2px' }} />
          </div>
        ))}
    </div>
  );
};

export default ServisInfoScreen;
