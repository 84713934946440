// Logovanje za cms (Admin)
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';

// Kreiraj podkategoriju (second category)
export const SECOND_CATEGORY_CREATE_REQUEST = 'SECOND_CATEGORY_CREATE_REQUEST';
export const SECOND_CATEGORY_CREATE_SUCCESS = 'SECOND_CATEGORY_CREATE_SUCCESS';
export const SECOND_CATEGORY_CREATE_FAIL = 'SECOND_CATEGORY_CREATE_FAIL';

// Vrati sve podkategorije (second category)
export const SECOND_CATEGORY_ALL_REQUEST = 'SECOND_CATEGORY_ALL_REQUEST';
export const SECOND_CATEGORY_ALL_SUCCESS = 'SECOND_CATEGORY_ALL_SUCCESS';
export const SECOND_CATEGORY_ALL_FAIL = 'SECOND_CATEGORY_ALL_FAIL';

// Vrati odredjenu podkategoriju (second category by ID)
export const SECOND_CATEGORY_GET_BY_ID_REQUEST =
  'SECOND_CATEGORY_GET_BY_ID_REQUEST';
export const SECOND_CATEGORY_GET_BY_ID_SUCCESS =
  'SECOND_CATEGORY_GET_BY_ID_SUCCESS';
export const SECOND_CATEGORY_GET_BY_ID_FAIL = 'SECOND_CATEGORY_GET_BY_ID_FAIL';

// Azuriraj podkategoriju (second category)
export const SECOND_CATEGORY_UPDATE_REQUEST = 'SECOND_CATEGORY_UPDATE_REQUEST';
export const SECOND_CATEGORY_UPDATE_SUCCESS = 'SECOND_CATEGORY_UPDATE_SUCCESS';
export const SECOND_CATEGORY_UPDATE_FAIL = 'SECOND_CATEGORY_UPDATE_FAIL';

// Obrisi podkategoriju (second category)
export const SECOND_CATEGORY_DELETE_REQUEST = 'SECOND_CATEGORY_DELETE_REQUEST';
export const SECOND_CATEGORY_DELETE_SUCCESS = 'SECOND_CATEGORY_DELETE_SUCCESS';
export const SECOND_CATEGORY_DELETE_FAIL = 'SECOND_CATEGORY_DELETE_FAIL';

// Svi postovi za podkategoriju (second category)
export const ALL_POST_FOR_SECOND_CATEGORY_REQUEST =
  'ALL_POST_FOR_SECOND_CATEGORY_REQUEST';
export const ALL_POST_FOR_SECOND_CATEGORY_SUCCESS =
  'ALL_POST_FOR_SECOND_CATEGORY_SUCCESS';
export const ALL_POST_FOR_SECOND_CATEGORY_FAIL =
  'ALL_POST_FOR_SECOND_CATEGORY_FAIL';

// Vrati odredjeni post za poslati ID
export const POST_GET_BY_ID_REQUEST = 'POST_GET_BY_ID_REQUEST';
export const POST_GET_BY_ID_SUCCESS = 'POST_GET_BY_ID_SUCCESS';
export const POST_GET_BY_ID_FAIL = 'POST_GET_BY_ID_FAIL';

// Azuriraj post
export const POST_UPDATE_REQUEST = 'POST_UPDATE_REQUEST';
export const POST_UPDATE_SUCCESS = 'POST_UPDATE_SUCCESS';
export const POST_UPDATE_FAIL = 'POST_UPDATE_FAIL';

// Obrisi post
export const POST_DELETE_REQUEST = 'POST_DELETE_REQUEST';
export const POST_DELETE_SUCCESS = 'POST_DELETE_SUCCESS';
export const POST_DELETE_FAIL = 'POST_DELETE_FAIL';

// Kreiraj post
export const POST_CREATE_REQUEST = 'POST_CREATE_REQUEST';
export const POST_CREATE_SUCCESS = 'POST_CREATE_SUCCESS';
export const POST_CREATE_FAIL = 'POST_CREATE_FAIL';

// Prikazi sve postove
export const ALL_POST_CMS_REQUEST = 'ALL_POST_CMS_REQUEST';
export const ALL_POST_CMS_SUCCESS = 'ALL_POST_CMS_SUCCESS';
export const ALL_POST_CMS_FAIL = 'ALL_POST_CMS_FAIL';

// Publish post
export const POST_PUBLISH_REQUEST = 'POST_PUBLISH_REQUEST';
export const POST_PUBLISH_SUCCESS = 'POST_PUBLISH_SUCCESS';
export const POST_PUBLISH_FAIL = 'POST_PUBLISH_FAIL';

// Unpublished post
export const POST_UNPUBLISHED_REQUEST = 'POST_UNPUBLISHED_REQUEST';
export const POST_UNPUBLISHED_SUCCESS = 'POST_UNPUBLISHED_SUCCESS';
export const POST_UNPUBLISHED_FAIL = 'POST_UNPUBLISHED_FAIL';

// Kreiraj servisnu informaciju
export const CREATE_SERVIS_INFO_REQUEST = 'CREATE_SERVIS_INFO_REQUEST';
export const CREATE_SERVIS_INFO_SUCCESS = 'CREATE_SERVIS_INFO_SUCCESS';
export const CREATE_SERVIS_INFO_FAIL = 'CREATE_SERVIS_INFO_FAIL';

// Prikazi sve postove za glavne kategirje novi zahtev
export const ALL_POST_FOR_PARENT_REQUEST = 'ALL_POST_FOR_PARENT_REQUEST';
export const ALL_POST_FOR_PARENT_SUCCESS = 'ALL_POST_FOR_PARENT_SUCCESS';
export const ALL_POST_FOR_PARENT_FAIL = 'ALL_POST_FOR_PARENT_FAIL';
