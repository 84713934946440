import {
  ALL_POST_CMS_FAIL,
  ALL_POST_CMS_REQUEST,
  ALL_POST_CMS_SUCCESS,
  ALL_POST_FOR_PARENT_FAIL,
  ALL_POST_FOR_PARENT_REQUEST,
  ALL_POST_FOR_PARENT_SUCCESS,
  ALL_POST_FOR_SECOND_CATEGORY_FAIL,
  ALL_POST_FOR_SECOND_CATEGORY_REQUEST,
  ALL_POST_FOR_SECOND_CATEGORY_SUCCESS,
  CREATE_SERVIS_INFO_FAIL,
  CREATE_SERVIS_INFO_REQUEST,
  CREATE_SERVIS_INFO_SUCCESS,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT,
  POST_CREATE_FAIL,
  POST_CREATE_REQUEST,
  POST_CREATE_SUCCESS,
  POST_DELETE_FAIL,
  POST_DELETE_REQUEST,
  POST_DELETE_SUCCESS,
  POST_GET_BY_ID_FAIL,
  POST_GET_BY_ID_REQUEST,
  POST_GET_BY_ID_SUCCESS,
  POST_PUBLISH_FAIL,
  POST_PUBLISH_REQUEST,
  POST_PUBLISH_SUCCESS,
  POST_UNPUBLISHED_FAIL,
  POST_UNPUBLISHED_REQUEST,
  POST_UNPUBLISHED_SUCCESS,
  POST_UPDATE_FAIL,
  POST_UPDATE_REQUEST,
  POST_UPDATE_SUCCESS,
  SECOND_CATEGORY_ALL_FAIL,
  SECOND_CATEGORY_ALL_REQUEST,
  SECOND_CATEGORY_ALL_SUCCESS,
  SECOND_CATEGORY_CREATE_FAIL,
  SECOND_CATEGORY_CREATE_REQUEST,
  SECOND_CATEGORY_CREATE_SUCCESS,
  SECOND_CATEGORY_DELETE_FAIL,
  SECOND_CATEGORY_DELETE_REQUEST,
  SECOND_CATEGORY_DELETE_SUCCESS,
  SECOND_CATEGORY_GET_BY_ID_FAIL,
  SECOND_CATEGORY_GET_BY_ID_REQUEST,
  SECOND_CATEGORY_GET_BY_ID_SUCCESS,
  SECOND_CATEGORY_UPDATE_FAIL,
  SECOND_CATEGORY_UPDATE_REQUEST,
  SECOND_CATEGORY_UPDATE_SUCCESS,
} from '../constants/cms';

export const loginReducer = (state = { userInfo: {} }, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return { loading: true };
    case LOGIN_SUCCESS:
      return { loading: false, userInfo: action.payload };
    case LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case LOGOUT:
      return {};
    default:
      return state;
  }
};

export const secondCategoryCreateReducer = (
  state = { secondCategoryCreate: {} },
  action
) => {
  switch (action.type) {
    case SECOND_CATEGORY_CREATE_REQUEST:
      return { loading: true, secondCategoryCreate: {} };
    case SECOND_CATEGORY_CREATE_SUCCESS:
      return { loading: false, secondCategoryCreate: action.payload };
    case SECOND_CATEGORY_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const secondCategoryAllReducer = (
  state = { secondCategoryAll: {} },
  action
) => {
  switch (action.type) {
    case SECOND_CATEGORY_ALL_REQUEST:
      return { loading: true, secondCategoryAll: {} };
    case SECOND_CATEGORY_ALL_SUCCESS:
      return { loading: false, secondCategoryAll: action.payload };
    case SECOND_CATEGORY_ALL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const secondCategoryGetByIdReducer = (
  state = { secondCategoryGetById: {} },
  action
) => {
  switch (action.type) {
    case SECOND_CATEGORY_GET_BY_ID_REQUEST:
      return { loading: true, secondCategoryGetById: {} };
    case SECOND_CATEGORY_GET_BY_ID_SUCCESS:
      return { loading: false, secondCategoryGetById: action.payload };
    case SECOND_CATEGORY_GET_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const secondCategoryUpdateReducer = (
  state = { secondCategoryUpdate: {} },
  action
) => {
  switch (action.type) {
    case SECOND_CATEGORY_UPDATE_REQUEST:
      return { loading: true, secondCategoryUpdate: {} };
    case SECOND_CATEGORY_UPDATE_SUCCESS:
      return { loading: false, secondCategoryUpdate: action.payload };
    case SECOND_CATEGORY_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const secondCategoryDeleteReducer = (
  state = { secondCategoryDelete: {} },
  action
) => {
  switch (action.type) {
    case SECOND_CATEGORY_DELETE_REQUEST:
      return { loading: true, secondCategoryDelete: {} };
    case SECOND_CATEGORY_DELETE_SUCCESS:
      return { loading: false, secondCategoryDelete: action.payload };
    case SECOND_CATEGORY_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postShowAllByCategoryReducer = (
  state = { postShowAllByCategory: {} },
  action
) => {
  switch (action.type) {
    case ALL_POST_FOR_SECOND_CATEGORY_REQUEST:
      return { loading: true, postShowAllByCategory: {} };
    case ALL_POST_FOR_SECOND_CATEGORY_SUCCESS:
      return { loading: false, postShowAllByCategory: action.payload };
    case ALL_POST_FOR_SECOND_CATEGORY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postGetByIdReducer = (state = { postGetById: {} }, action) => {
  switch (action.type) {
    case POST_GET_BY_ID_REQUEST:
      return { loading: true, postGetById: {} };
    case POST_GET_BY_ID_SUCCESS:
      return { loading: false, postGetById: action.payload };
    case POST_GET_BY_ID_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postUpdateReducer = (state = { postUpdated: {} }, action) => {
  switch (action.type) {
    case POST_UPDATE_REQUEST:
      return { loading: true, postUpdated: {} };
    case POST_UPDATE_SUCCESS:
      return { loading: false, postUpdated: action.payload };
    case POST_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postDeleteReducer = (state = { postDeleted: {} }, action) => {
  switch (action.type) {
    case POST_DELETE_REQUEST:
      return { loading: true, postDeleted: {} };
    case POST_DELETE_SUCCESS:
      return { loading: false, postDeleted: action.payload };
    case POST_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postCreateReducer = (state = { postCreate: {} }, action) => {
  switch (action.type) {
    case POST_CREATE_REQUEST:
      return { loading: true, postCreate: {} };
    case POST_CREATE_SUCCESS:
      return { loading: false, postCreate: action.payload };
    case POST_CREATE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postPublishReducer = (state = { postPublish: {} }, action) => {
  switch (action.type) {
    case POST_PUBLISH_REQUEST:
      return { loading: true, postPublish: {} };
    case POST_PUBLISH_SUCCESS:
      return { loading: false, postPublish: action.payload };
    case POST_PUBLISH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const postUnPublishedReducer = (
  state = { postUnpublished: {} },
  action
) => {
  switch (action.type) {
    case POST_UNPUBLISHED_REQUEST:
      return { loading: true, postUnpublished: {} };
    case POST_UNPUBLISHED_SUCCESS:
      return { loading: false, postUnpublished: action.payload };
    case POST_UNPUBLISHED_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allPostCmsReducer = (state = { allPostCms: {} }, action) => {
  switch (action.type) {
    case ALL_POST_CMS_REQUEST:
      return { loading: true, allPostCms: {} };
    case ALL_POST_CMS_SUCCESS:
      return { loading: false, allPostCms: action.payload };
    case ALL_POST_CMS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const createServisInfoReducer = (
  state = { createServisInfo: {} },
  action
) => {
  switch (action.type) {
    case CREATE_SERVIS_INFO_REQUEST:
      return { loading: true, createServisInfo: {} };
    case CREATE_SERVIS_INFO_SUCCESS:
      return { loading: false, createServisInfo: action.payload };
    case CREATE_SERVIS_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allPostForParentReducer = (
  state = { allPostForParent: {} },
  action
) => {
  switch (action.type) {
    case ALL_POST_FOR_PARENT_REQUEST:
      return { loading: true, allPostForParent: {} };
    case ALL_POST_FOR_PARENT_SUCCESS:
      return { loading: false, allPostForParent: action.payload };
    case ALL_POST_FOR_PARENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
