import React, { useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { homeCategoryActions } from '../../actions/categoryActions';
import { secondCategoryCreateActions } from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const SecondCategoryCreateScreen = () => {
  const [name, setName] = useState('');
  const [parent_id, setParentId] = useState('');
  let [logoName, setLogoName] = useState('');
  const [logo, setLogo] = useState(null);
  let [imageName, setImageName] = useState('');
  const [image, setImage] = useState(null);
  const [message, setMessage] = useState(null);
  const [imageMessage, setImageMessage] = useState(null);

  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  const convertTo64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (err) => {
        reject(err);
      };
    });
  };

  const dispatch = useDispatch();

  const majorCategoryData = useSelector((state) => state.majorCategory);
  const { majorCategory } = majorCategoryData;

  const secondCategoryCreateData = useSelector(
    (state) => state.secondCategoryCreate
  );
  const { loading, error, secondCategoryCreate } = secondCategoryCreateData;

  // console.log('SECOND', secondCategoryCreate);

  useEffect(() => {
    dispatch(homeCategoryActions());
  }, [dispatch]);

  let logoNameSplit;
  let imageNameSplit;
  const submitHandler = (e) => {
    e.preventDefault();

    logoNameSplit = logoName.split('\\');
    logoNameSplit = logoNameSplit[logoNameSplit.length - 1].split('.');
    logoNameSplit = logoNameSplit[0];
    logoName = logoNameSplit;

    imageNameSplit = imageName.split('\\');
    imageNameSplit = imageNameSplit[imageNameSplit.length - 1].split('.');
    imageNameSplit = imageNameSplit[0];
    imageName = imageNameSplit;

    dispatch(
      secondCategoryCreateActions(
        name,
        parent_id,
        logoName,
        logo,
        imageName,
        image
      )
    );
    setMessage('Podkategorija je uspesno kreirana!');
  };

  return (
    <Container>
      <Form onSubmit={submitHandler}>
        {loading && <Loader />}
        {message && <Message variant='success'>{message}</Message>}
        {error && <Message variant='danger'>{error}</Message>}
        <Form.Group className='mb-3' controlId='name'>
          <Form.Label>Naziv lokaliteta *</Form.Label>
          <Form.Control
            type='text'
            placeholder='Unesite naziv kategorije'
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='parent_id'>
          <Form.Select
            name='parent_id'
            value={parent_id}
            onChange={(e) => setParentId(e.target.value)}
          >
            <option>Izaberi glavnu kategoriju *</option>
            {majorCategory?.data &&
              majorCategory?.data.map((category) => (
                <option key={category.id} value={category.id}>
                  {category.name}
                </option>
              ))}
          </Form.Select>
        </Form.Group>

        <Form.Group className='mb-3' controlId='logoName'>
          <Form.Label>Logo *</Form.Label>
          <Form.Control
            type='file'
            value={logoName}
            onChange={async (e) => {
              let imageName = e.target.value;
              setLogoName(imageName);
              if (e.target.files[0].size > 8000000) {
                setImageMessage('Slika mora biti ispod 8 mb');
              } else {
                const base = await convertTo64(e.target.files[0]);
                setLogo(base);
                setImageMessage(null);
              }
            }}
          />
        </Form.Group>

        <Form.Group className='mb-3' controlId='imageName'>
          <Form.Label>Slika *</Form.Label>
          <Form.Control
            type='file'
            value={imageName}
            onChange={async (e) => {
              let imageNameData = e.target.value;
              setImageName(imageNameData);
              if (e.target.files[0].size > 8000000) {
                setImageMessage('Slika mora biti ispod 8 mb');
              } else {
                const base = await convertTo64(e.target.files[0]);
                setImage(base);
                setImageMessage(null);
              }
            }}
          />
        </Form.Group>

        {imageMessage && <Message variant='danger'>{imageMessage}</Message>}

        <Button variant='primary' type='submit' className='mt-2'>
          Kreiraj lokalitet
        </Button>
      </Form>
    </Container>
  );
};

export default SecondCategoryCreateScreen;
