import React, { useEffect, useState } from 'react';
import { Button, FloatingLabel, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import pell from 'pell';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  postCreateActions,
  secondCategoryAllActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { homeCategoryActions } from '../../actions/categoryActions';
import { secondCategoryActions } from '../../actions/secondCategoryActions';
import PostSection from '../../components/PostSection';

const CreateNewPostFromSecCategoryScreen = () => {
  const initEditor = () => {
    pell.init({
      element: document.getElementById('custom-editor'),
      onChange: (html) => setDescription(html),
      defaultParagraphSeparator: 'div',
      styleWithCSS: false,
      actions:  ['bold', 'underline', 'italic', 'link', 'heading1', 'heading2', 'olist']
    });
  };

  useEffect(() => {
    initEditor();
  }, []);

  const dispatch = useDispatch();

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  const [post, setPost] = useState('');
  const [description, setDescription] = useState('');
  const [created_at, setCreatedAt] = useState('');
  let category_ids = [];
  const [message, setMessage] = useState(null);

  const postCreateData = useSelector((state) => state.postCreate);
  const { error, loading, postCreate } = postCreateData;

  useEffect(() => {
    if (postCreate?.status_code === 200) {
      setMessage('Post je uspesno kreiran!');
    }
  }, [postCreate]);

  const location = useLocation();
  const data = location?.state?.data;

  category_ids.push(data);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(postCreateActions(post, description, created_at, category_ids));
    setPost('');
    setCreatedAt('');
  };

  return (
    <Form onSubmit={submitHandler}>
      <Form.Group className='mb-3' controlId='post'>
        <Form.Label>Naziv Posta *</Form.Label>
        <Form.Control
          type='text'
          placeholder='Unesite naziv posta'
          value={post}
          onChange={(e) => setPost(e.target.value)}
        ></Form.Control>
      </Form.Group>
      <div
        style={{ background: 'white' }}
        id='custom-editor'
      ></div>

      <Form.Group className='mb-3' controlId='created_at'>
        <Form.Label>Datum za koji dan je post *</Form.Label>
        <Form.Control
          type='date'
          value={created_at}
          onChange={(e) => setCreatedAt(e.target.value)}
        />
      </Form.Group>

      {message && !error && <Message variant='success'>{message}</Message>}
      {error && <Message variant='danger'>{error}</Message>}
      {loading && <Loader />}

      <div>
        <h3>Prikaz</h3>
        <PostSection date={created_at} title={post} description={description} />
      </div>

      <Button type='submit' variant='primary' className='mt-2'>
        Kreiraj Vest
      </Button>
    </Form>
  );
};

export default CreateNewPostFromSecCategoryScreen;
