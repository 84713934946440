import React, { useEffect } from 'react';
import { Button, Card, Container, Row, Col } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  postDeleetActions,
  postPublishActions,
  postShowAllByCategoryActions,
  postUnpublishedActions,
} from '../../actions/cmsActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const PostShowAllByCategoryScreen = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  let { id } = useParams();

  const postShowAllByCategoryData = useSelector(
    (state) => state.postShowAllByCategory
  );
  const { loading, error, postShowAllByCategory } = postShowAllByCategoryData;

  useEffect(() => {
    dispatch(postShowAllByCategoryActions(id));
  }, [dispatch, id]);

  // Publish
  const postPublishData = useSelector((state) => state.postPublish);
  const { postPublish } = postPublishData;

  const publishHandler = (postId) => {
    dispatch(postPublishActions(postId));
    dispatch(postShowAllByCategoryActions(id));
  };

  // Unpublished
  const postUnpublishedData = useSelector((state) => state.postUnpublished);
  const { postUnpublished } = postUnpublishedData;

  const unpublishedHandler = (postId) => {
    dispatch(postUnpublishedActions(postId));
    dispatch(postShowAllByCategoryActions(id));
  };

  const deleteHandler = (post_id) => {
    if (window.confirm('Da li ste sigurni da zelite da obrisete vest?')) {
      // DELETE SECOND CATEGORY
      dispatch(postDeleetActions(post_id));
      dispatch(postShowAllByCategoryActions(id));
    }
  };

  return (
    <Container>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      <Card className='my-2' style={{ textAlign: 'center' }}>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/create-new-post'>
            Kreiraj novu vest
          </Link>
        </Card.Body>
      </Card>

      {postShowAllByCategory?.data?.posts &&
        postShowAllByCategory.data.posts.map((post) => (
          <Card className='my-2' key={post.id}>
            <Card.Body>
              <Row>
                <Col>
                  <Card.Title>
                    <Link to={`/posts/${post.id}`}>{post.title}</Link>
                  </Card.Title>
                </Col>
                <Col>Status: {post.status}</Col>
                <Col>
                  {post.status === 'publish' ? (
                    <Button
                      variant='info'
                      onClick={() => unpublishedHandler(post.id)}
                    >
                      <i className='fa-solid fa-delete-left'></i>
                    </Button>
                  ) : (
                    <Button
                      variant='info'
                      onClick={() => publishHandler(post.id)}
                    >
                      <i className='fa fa-upload' aria-hidden='true'></i>
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    variant='danger'
                    onClick={() => deleteHandler(post.id)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        ))}
    </Container>
  );
};

export default PostShowAllByCategoryScreen;
