import React, { useEffect } from 'react';
import { Button, Card, Container, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { postDeleetActions } from '../../actions/cmsActions';
import { allServiceInfoActions } from '../../actions/postActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const ServisInfoAllScreen = () => {
  // Instanciranje dispatcha
  const dispatch = useDispatch();

  //   Provera korisnika
  const userLogin = useSelector((state) => state.userInfo);
  const { userInfo } = userLogin;

  const admin = JSON.parse(localStorage.getItem('userInfo'));

  let history = useNavigate();
  useEffect(() => {
    if (admin?.status_code !== 200) {
      history('/cms-login');
    }
  }, [admin, history]);

  // Listanje svih servisnih informacija
  const allServicesInfoData = useSelector((state) => state.allServicesInfo);
  const { loading, allServicesInfo } = allServicesInfoData;

  useEffect(() => {
    dispatch(allServiceInfoActions());
  }, [dispatch]);

  //   Azuriranje servisne informacije

  //   Brisanje servisne informacije
  const deleteHandler = (id) => {
    if (window.confirm('Da li ste sigurni da zelite da obrisete lokalitet?')) {
      // DELETE SECOND CATEGORY
      dispatch(postDeleetActions(id));
      dispatch(allServiceInfoActions());
    }
  };

  return (
    <Container>
      {loading && <Loader />}
      {/* {error && <Message variant='danger'>{error}</Message>} */}

      <Card className='my-2' style={{ textAlign: 'center' }}>
        <Card.Body>
          <Link style={{ textDecoration: 'none' }} to='/create-servis-info'>
            Kreiraj servisnu informaciju
          </Link>
        </Card.Body>
      </Card>

      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Naziv</th>
            <th>Azuriraj</th>
            <th>Obrisi</th>
          </tr>
        </thead>
        <tbody>
          {allServicesInfo?.data?.posts &&
            allServicesInfo?.data?.posts.map((info) => (
              <tr key={info.id}>
                <td>{info.title}</td>
                <td>
                  <Link to={`/update-servis-info/${info.id}`}>Azuriraj</Link>
                </td>
                <td>
                  {/* <Button variant='danger'>Obrisi</Button>
                   */}
                  <Button
                    variant='danger'
                    onClick={() => deleteHandler(info.id)}
                  >
                    <i className='fas fa-trash'></i>
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default ServisInfoAllScreen;
