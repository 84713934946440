import React, { useEffect, useState } from 'react';
import { Button, Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { allPostForParentActions } from '../../actions/cmsActions';
import { allPostActions, allPostDatesActions } from '../../actions/postActions';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import PostSection from '../../components/PostSection';
import { detectLayoutColor } from '../../helpers/utils';

import styles from '../../styles/Layout.module.css';

const CulturalCentreScreen = () => {
  const dispatch = useDispatch();

  const location = useLocation();
  const detectedColor = detectLayoutColor(location.pathname);

  let { id } = useParams();
  let dates_id = 0;
  let click = false;

  // const allPostData = useSelector((state) => state.allPost);
  // const { loading, error, allPost } = allPostData;

  const allPostDatesData = useSelector((state) => state.allPostDates);
  const { allPostDates } = allPostDatesData;

  let today = new Date().toISOString().slice(0, 10);

  // Paginacija
  const [page, setPage] = useState('');
  const [firstPage, setFirstPage] = useState('');
  const [nextPage, setNextPage] = useState('');
  const [previousPage, setPreviousPage] = useState('');
  const [lastPage, setLastPage] = useState('');
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    if (click === false) {
      dispatch(allPostActions(id, today));
    }
  }, [click, id, today, dispatch]);

  useEffect(() => {
    dispatch(allPostDatesActions(id));
  }, [dispatch, id]);

  const allPostForParentData = useSelector((state) => state.allPostForParent);
  const { loading, error, allPostForParent } = allPostForParentData;

  useEffect(() => {
    dispatch(allPostForParentActions(page, id));
  }, [dispatch, page, id]);

  useEffect(() => {
    if (allPostForParent?.data) {
      setFirstPage('1');
      setPreviousPage(allPostForParent?.data?.prev_page_url);
      setLastPage(allPostForParent?.data?.last_page);
      setNextPage(allPostForParent?.data?.next_page_url);
      setCurrentPage(allPostForParent?.data?.current_page);
    }
  }, [allPostForParent]);

  const paginationFirstHandler = (e) => {
    e.preventDefault();
    let page = firstPage;
    setPage(page);
    dispatch(allPostForParentActions(page, id));
  };

  const paginationNextHandler = (e) => {
    e.preventDefault();
    let page;
    if (nextPage) {
      page = nextPage.split('=')[1];
      setPage(page);
    }
    dispatch(allPostForParentActions(page, id));
  };

  const paginationPrevHandler = (e) => {
    e.preventDefault();
    let page;
    if (previousPage) {
      page = previousPage.split('=')[1];
      setPage(page);
    }
    dispatch(allPostForParentActions(page, id));
  };

  const paginationLastHandler = (e) => {
    e.preventDefault();
    let page = lastPage;
    setPage(page);
    allPostForParentActions(page, id);
  };

  return (
    <div className={detectedColor + ` ${styles.layoutContent}`}>
      {loading && <Loader />}
      {error && <Message variant='danger'>{error}</Message>}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          border: '1px solid #ececec',
        }}
      >
        {allPostDates?.data &&
          allPostDates.data.map((dt, index) => (
            <span
              key={dates_id++}
              className='cp'
              style={{
                background: index == 0 ? `#006947` : 'transparent',
                border: index != 0 && '2px solid #006947',
                color: index == 0 ? 'white' : '#006947',
                padding: '5px 20px',
                margin: '5px',
                borderRadius: '20px',
                textAlign: 'center',
              }}
              onClick={(e) => {
                e.preventDefault();
                dispatch(allPostActions(id, dt.created_at.split('T')[0]));
                click += true;
              }}
            >
              {dt.created_at.split('T')[0].split('-')[1]}
              <br />
              <h2>{dt.created_at.split('T')[0].split('-')[2]}</h2>
            </span>
          ))}
      </div>

      {allPostForParent?.data?.data.length > 0 &&
        allPostForParent.data.data.map((post) => (
          <div key={dates_id++}>
            <Link
              to='/show-post'
              style={{ textDecoration: 'none' }}
              state={{ data: post }}
            >
              <PostSection
                date={post.post_datetime}
                title={post.title}
                logo={post.categories[0].logo}
                description={post.description}
              />
            </Link>
          </div>
        ))}
      <Pagination className='justify-content-center mb-4 my-5'>
        <Pagination.First onClick={paginationFirstHandler} />
        <Pagination.Prev onClick={paginationPrevHandler} />
        {/* <Pagination.Ellipsis /> */}
        <Pagination.Item disabled>{currentPage}</Pagination.Item>
        <Pagination.Next onClick={paginationNextHandler} />
        <Pagination.Last onClick={paginationLastHandler} />
      </Pagination>
      {loading == false && allPostForParent?.data?.data.length == 0 && (
        <section>
          <h1 className='main-title-content'>
            Za danasnji dan nema najavljenih desavanja.
          </h1>
          <p className='description-content'>
            {' '}
            Izaberite neki predstojeci datum iz gore ponudjenih.{' '}
          </p>
          <p className='note-content'>
            * Ukoliko nema ponudjenih datuma ne predstoje desavanja za izabrani
            lokalitet.
          </p>
        </section>
      )}
    </div>
  );
};

export default CulturalCentreScreen;
