import {
  ALL_POST_DATES_FAIL,
  ALL_POST_DATES_REQUEST,
  ALL_POST_DATES_SUCCESS,
  ALL_POST_FAIL,
  ALL_POST_REQUEST,
  ALL_POST_SUCCESS,
  ALL_SERVIS_INFO_FAIL,
  ALL_SERVIS_INFO_REQUEST,
  ALL_SERVIS_INFO_SUCCESS,
} from '../constants/post';

export const allPostReducer = (state = { allPost: {} }, action) => {
  switch (action.type) {
    case ALL_POST_REQUEST:
      return { loading: true, allPost: {} };
    case ALL_POST_SUCCESS:
      return { loading: false, allPost: action.payload };
    case ALL_POST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allPostDatesReducer = (state = { allPostDates: {} }, action) => {
  switch (action.type) {
    case ALL_POST_DATES_REQUEST:
      return { loading: true, allPostDates: {} };
    case ALL_POST_DATES_SUCCESS:
      return { loading: false, allPostDates: action.payload };
    case ALL_POST_DATES_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const allServisInfoReducer = (
  state = { allServicesInfo: {} },
  action
) => {
  switch (action.type) {
    case ALL_SERVIS_INFO_REQUEST:
      return { loading: true, allServicesInfo: {} };
    case ALL_SERVIS_INFO_SUCCESS:
      return { loading: false, allServicesInfo: action.payload };
    case ALL_SERVIS_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
