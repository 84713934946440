import React from 'react';
import { useLocation } from 'react-router-dom';

const ColorWrapperDetect = ({ children }) => {
    const location = useLocation();
    let color = '#ebeae9';
    if(location.pathname == '/' || location.pathname.includes('servisne-informacije')) {
        color = "white"
    } 
    return <main style={{ background: color }} id="mainSelect" className="mainSect">
        {children}
    </main>
}

export default ColorWrapperDetect;